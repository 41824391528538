<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} forma de pago`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0"       
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="paymentMethod.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de forma de pago"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeMethods"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue')
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      paymentMethod: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      methods: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    activeMethods() {
      return this.methods.filter(pm => pm.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.paymentMethod)
    }
  },
  async created() {
    this.paymentMethod.id_autor = this.userData.id
    this.paymentMethod.id_institucion_educativa = this.institutionId
    this.methods = await this.fetchPaymentMethodsByEI(this.institutionId)

  },
  methods: {
    async fetchPaymentMethodsByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/forma-de-pago-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener forma de pago por institucion educativa', error);
      }
    },
    async createPaymentMethods() {
      try {
        const response = await api.post('/administracion/crear-forma-pago', {
          forma_de_pago: this.paymentMethod.dato,
          id_autor: this.paymentMethod.id_autor,
          id_institucion_educativa: this.paymentMethod.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la forma de pago.')
        }
      } catch (error) {
        console.error('Error al intentar crear forma de pago', error)
        this.errors.push('Error al intentar crear forma de pago')
      }
    },
    async updatePaymentMethods() {
      try {
        const response = await api.patch('/administracion/update-forma-pago', {
          id: this.paymentMethod.id,
          forma_pago: this.paymentMethod.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la forma de pago')
        }
      } catch (error) {
        console.error('Error al intentar actualizar forma de pago', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deletePaymentMethods(typeId) {
      try {
        const response = await api.post('/administracion/delete-forma-pago', {
          id_forma_de_pago: typeId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la forma de pago')
        }
      } catch (error) {
        console.error('Error al intentar eliminar forma de pago', error);
      }
    },
    async save() {
      await this.createPaymentMethods()
      this.methods = await this.fetchPaymentMethodsByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.paymentMethod = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.paymentMethod = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.paymentMethod = {...item}
      await this.updatePaymentMethods()
      this.methods = await this.fetchPaymentMethodsByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deletePaymentMethods(itemId)
      this.methods = await this.fetchPaymentMethodsByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>